<template>
  <div class="mine-container">
    <!-- 身份卡片 -->
    <div class="info-card flex">
      <!-- 用户信息 -->
      <div class="left-info flex">
        <img :src="info.avatar" class="avatar" />
        <div class="info">
          <div class="name fs-20 fc-1">{{info.name}}</div>
          <div class="phone fs-16 fc-6">{{info.phone}}</div>
        </div>
      </div>
      <!-- 用户身份 -->
      <div class="type" v-show="info.type !== 0">
        <div class="sub-title fs-14 fc-6">身份</div>
        <div class="type-text fs-20 fc-1">{{info.type|identityText}}</div>
      </div>
      <div class="principal" v-show="info.type === 2">
        <div class="fs-14 fc-6">负责人</div>
        <div class="fs-20 fc-1">{{info.organization && info.organization.manager_name}}</div>
      </div>
      <!-- 操作按钮 -->
      <div class="btns-content flex">
        <div
          class="btn red fs-16 fc-f"
          v-show="info.type === 0"
          @click="showMiniprogramCode"
        >申请成为学习伙伴</div>
        <div
          class="btn orange fs-16 fc-f"
          v-show="info.type !== 2"
          @click="applyOrganization"
        >申请成为机构伙伴</div>
      </div>
    </div>
    <!-- 提示信息 -->
    <div class="tips orange fs-12 fc-f" v-show="info.status === 1">机构伙伴申请已提交，请耐心等待审核结果</div>
    <div
      class="tips red cursor fs-12 fc-f"
      v-show="info.status === 4"
      @click="showErrorMsg"
    >机构伙伴申请失败，点击查看原因</div>
    <!-- 学员课程 -->
    <div class="course-content" v-show="info.type === 1 && courseList.length > 0">
      <div class="course-title fs-20 fc-3">已完成课程列表</div>
      <div class="course-list flex">
        <div class="course-item flex" v-for="(item, index) in courseList" :key="index">
          <div class="course-item-content">
            <img :src="item.course.cover" class="course-img" />
            <div class="info">
              <div class="title fs-18 fc-3">{{item.course.title}}</div>
              <div class="date fs-12 fc-6 lm-2">完成时间：{{item.updated_at}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 机构数据 -->
    <div class="organization-content" v-show="info.type === 2">
      <div class="head-content flex">
        <div class="organization-title fs-20 fc-3">机构数据</div>
        <div class="btn orange fs-16 fc-f" @click="goForm">月度活动信息</div>
      </div>
      <div class="organization-table">
        <div class="table-head flex fs-14 fc-2">
          <div class="head-item name">项目名称</div>
          <div class="head-item month-data">{{month}}月</div>
          <div class="head-item year-data">{{year}}年</div>
          <div class="head-item total">合计</div>
        </div>
        <div class="table-content">
          <div class="table-cell flex" v-for="(item, index) in reportsData" :key="index">
            <div class="name fs-14 fc-2">{{item.name}}</div>
            <div class="month-data fs-14 fc-2">{{item.monthData + item.unit}}</div>
            <div class="year-data fs-14 fc-2">{{item.yearData + item.unit}}</div>
            <div class="total fs-14 fc-2">{{item.total + item.unit}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="500px" center :modal="false">
      <div class="dialog-content">
        <div class="dialog-title fs-20 fc-3">申请成为学员伙伴</div>
        <div class="del-dialog-cnt fc-6">步骤一：使用微信扫描以下二维码进入义仓小程序</div>
        <img src="../../../assets/yicang_QRCode.jpg" class="qr-code" />
        <div class="del-dialog-cnt fc-3">步骤二：进入“义仓课堂”选择课程报名参加</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API, REPORTS_DATA } from "@/const/const";

export default {
  name: "newsDetail",
  data() {
    return {
      dialogVisible: false,
      info: {
        avatar: require("@/assets/avatar.png"),
        name: "",
        phone: "",
        type: 0, // 用户身份状态：0:游客；1:学员伙伴；2:机构伙伴
        status: 3 // 申请状态：applying: 1, audit_pass: 2, review_pass: 3, reject: 4
      },
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      reportsData: REPORTS_DATA,
      courseList: []
    };
  },
  filters: {
    identityText(val) {
      const type = val === 0 ? "游客" : val === 1 ? "学员伙伴" : "机构伙伴";
      return type;
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      if (!userInfo.open_id) return;
      request
        .get(API.GET_USERINFO + `/${userInfo.open_id}`)
        .then(res => {
          this.info = res;
          if (res.type === 1) {
            this.getCoursesList();
          }
          if (res.type === 2) {
            this.getReportsData();
          }
          sessionStorage.setItem("userInfo", JSON.stringify(res));
        })
        .catch(err => {
          if (err.code === 401) {
            this.$alert("用户信息已更新，请重新扫码登录", "温馨提醒", {
              confirmButtonText: "确定",
              callback: action => {
                this.logout();
              }
            });
          }
        });
    },
    getCoursesList() {
      request
        .get(API.GET_MY_COUSRSES_LIST, {
          is_my_course: true,
          status: "finished",
          page_size: 100 // 默认分页接口，此处业务需求上要全部数据
        })
        .then(res => {
          this.courseList = res.data;
        });
    },
    getReportsData() {
      request.get(API.REPORTS_DATA).then(res => {
        this.reportsData.map(item => {
          item.monthData = res.current_month[item.key];
          item.yearData = res.current_year[item.key];
          item.total = res.history[item.key];
          return item;
        });
      });
    },
    showErrorMsg() {
      this.$alert(this.info.organization.reason, "拒绝原因", {
        confirmButtonText: "确定"
      });
    },
    logout() {
      sessionStorage.setItem("userInfo", JSON.stringify({}));
      this.$router.push({ path: "/" });
    },
    goForm() {
      this.$router.push({ path: `/organizationData` });
    },
    applyOrganization() {
      if (this.info.status === 1) {
        this.$alert("您已提交审核，请等待审核结果～", "温馨提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.$router.push({ path: `/applyOrganization` });
    },
    showMiniprogramCode() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="less" scoped>
.mine-container {
  padding: 0.56rem 2.8rem;
  min-height: 4.5rem;
  .info-card {
    margin-bottom: 0.1rem;
    border-radius: 0.02rem;
    background-color: #fff;
    box-shadow: 0 0.06rem 0.42rem 0 rgba(0, 0, 0, 0.1);
    padding: 0.22rem 0.48rem;
    justify-content: space-between;
    .left-info {
      .avatar {
        width: 0.72rem;
        height: 0.72rem;
        border-radius: 0.72rem;
      }
      .info {
        padding-left: 0.24rem;
        .name {
          padding-bottom: 0.1rem;
        }
      }
    }
    .type {
      border-left: 0.01rem solid #eee;
      border-right: 0.01rem solid #eee;
      padding: 0 0.8rem;
      text-align: center;
    }
    .principal {
      padding: 0 0.8rem;
      text-align: center;
    }
    .btns-content {
      .btn {
        padding: 0.12rem 0.28rem;
        border-radius: 0.02rem;
        margin-left: 0.36rem;
        cursor: pointer;
      }
    }
  }
  .red {
    background-color: #c43b4b;
  }
  .orange {
    background-color: #dbbb85;
  }
  .gray {
    background-color: #d3d3d3;
  }
  .cursor {
    cursor: pointer;
  }
  .tips {
    text-align: center;
    border-radius: 0.02rem;
    margin-bottom: 0.1rem;
  }

  .course-content {
    margin-bottom: 0.1rem;
    border-radius: 0.02rem;
    padding: 0 0.28rem;
    box-shadow: 0 0.06rem 0.42rem 0 rgba(0, 0, 0, 0.1);
    .course-title {
      padding: 0.4rem 0 0.26rem;
      font-weight: 500;
    }
    .course-list {
      flex-wrap: wrap;
      .course-item {
        flex-direction: column;
        align-items: flex-start;
        width: 31%;
        margin: 0 0.09rem 0.36rem;
        .course-item-content {
          box-shadow: 0 0.06rem 0.42rem 0 rgba(0, 0, 0, 0.1);
        }
        .course-img {
          width: 100%;
        }
        .info {
          .title {
            font-weight: 500;
            padding: 0.16rem 0.2rem;
          }
          .date {
            padding: 0 0.2rem 0.2rem;
          }
        }
      }
    }
  }

  .organization-content {
    margin-bottom: 0.1rem;
    border-radius: 0.02rem;
    padding: 0 0.28rem 0.6rem;
    background-color: #fff;
    box-shadow: 0 0.06rem 0.42rem 0 rgba(0, 0, 0, 0.1);
    .head-content {
      justify-content: space-between;
      .organization-title {
        padding: 0.4rem 0 0.26rem;
        font-weight: 500;
      }
      .btn {
        padding: 0.12rem 0.28rem;
        border-radius: 0.02rem;
        margin-left: 0.36rem;
        cursor: pointer;
      }
    }
    .organization-table {
      .table-head {
        background-color: #f3f3f3;
        border-radius: 0.04rem;
        margin-bottom: 0.03rem;
        padding: 0.15rem 0;
      }
      .table-content {
        .table-cell {
          border: 0.01rem solid #f3f3f3;
          background-color: #fff;
          border-radius: 0.04rem;
          margin-bottom: 0.03rem;
          padding: 0.15rem 0;
        }
      }

      .name {
        width: 2rem;
        text-align: center;
      }
      .month-data {
        width: 2rem;
        text-align: center;
      }
      .year-data {
        width: 2rem;
        text-align: center;
      }
      .total {
        width: 2rem;
        text-align: center;
      }
    }
  }
}
.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .dialog-title {
    padding-bottom: 0.2rem;
    font-weight: 600;
    text-align: center;
  }
  .qr-code {
    width: 50%;
    margin: .4rem auto;
  }
}
</style>